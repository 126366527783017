<template>
	<div>
		<van-nav-bar
			title="完善信息"
			left-arrow
			fixed
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		
		<div class="information">
			<h2 class="title qjc-c-dark qjc-texta-l">完善信息</h2>
			<p class="title2 qjc-texta-l qjc-fts-32">請完善您的個人信息</p>
			<div class="box qjc-input-group qjc-bg-fff">
				<van-radio-group v-model="sex">
					<dl>
						<dt>
							<van-image
								width="1.3rem"
								height="1.3rem"
								:src="require('@/assets/images/nan.png')"
							/>
						</dt>
						<dd class="sex-name qjc-fts-28 qjc-c-dark qjc-ftw-b">男生</dd>
						<dd>
							<van-radio name='1'></van-radio>
						</dd>
					</dl>
					<dl>
						<dt>
							<van-image
								width="1.3rem"
								height="1.3rem"
								:src="require('@/assets/images/nv.png')"
							/>
						</dt>
						<dd class="sex-name qjc-fts-28 qjc-c-dark qjc-ftw-b">女生</dd>
						<dd>
							<van-radio name='2'></van-radio>
						</dd>
					</dl>
				</van-radio-group>
				<van-field
					placeholder="請輸入您的姓名"
					v-model="name"
				/>
				<van-field
					placeholder="請選擇您的生日"
					v-model="birth"
					right-icon="arrow-down"
					@focus="birthShow = true"
				/>
			</div>
			<van-button @click="regist" round class="btn qjc-fts-36 qjc-bg-primary qjc-c-fff">確認</van-button>
		</div>
		
		<!-- 日期选择弹出框 -->
		<van-popup
			v-model="birthShow"
			position="bottom"
		>
			<van-datetime-picker
				type="date"
				:min-date="minDate"
				:max-date="maxDate"
				v-model='selectBirth'
				@cancel="birthShow = false"
				@confirm="selBirth"
			/>
		</van-popup>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, RadioGroup, Radio, Image, Field, DatetimePicker, Popup, Button, Toast } from 'vant'
	Vue.use(NavBar)
	   .use(RadioGroup)
	   .use(Radio)
	   .use(Image)
	   .use(Field)
	   .use(DatetimePicker)
	   .use(Popup)
	   .use(Button)
	   .use(Toast);
	
	import { verifyCode, profile } from '@/js/axios.js'
	import { isPhone, isHKPhone, isEmail, pwdReg } from '@/js/utils.js'
	
	export default{
		name: 'registPerfectInfo',
		data (){
			return {
				// form: JSON.parse(sessionStorage.getItem('registForm')) || {},//上一步中存储的表单数据 
				sex: '',//性别 1男  2女
				name: '',//姓名
				birth: '',//生日(字符串，传参不可用，给用户观看)
				birthShow: false,//生日选择器是否显示
				selectBirth: new Date('1980/1/1')//生日选择器日期 默认为20年前
			}
		},
		computed: {
			//年龄为20-90岁之间(直接绑定在组件上会卡死)
			minDate (){
				return this.limitDate(90);
			},
			maxDate (){
				return this.limitDate(20);
			}
		},
		mounted() {
			// 没有注册，直接完善信息
			// this.isRegist1();
			
			document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
		  document.querySelector('body').removeAttribute('style')
		},
		methods: {
			limitDate (year){//参数：距今多少年
				let now = new Date();
				return new Date((now.getFullYear()-year)+'/'+(now.getMonth()+1)+'/'+now.getDate());
			},
			selBirth (value){
				this.birth = this.dateString(value);
				this.birthShow = false;
			},
			dateString (date){
				var year = date.getFullYear(),
					m = date.getMonth() + 1,
					d = date.getDate();
				return year + '/' + m + '/' + d;
			},
			
			//验证是否是从注册第一步过来(防止跳过第一步，直接第二步)
			// isRegist1 (){
			// 	if(this.form == {} || this.form.isAgree == false || !pwdReg(this.form.password) || !(isPhone(this.form.account) || isHKPhone(this.form.account)) || this.form.code.trim() == ''){
			// 		verifyCode({
			// 			account: this.form.account,
			// 			code: this.form.code,
			// 			// type: (isPhone(this.account) || isHKPhone(this.account))?1:(isEmail(this.account)?90:'')//1.手机号注册 2 手机找回密码 90.邮箱注册 91.邮箱找回密码
			// 			type: 1
			// 		}).then( res => {
			// 			if(res.status != 1){
			// 				Toast.fail('請先填寫註冊信息');
			// 				this.$router.push('/regist');
			// 			}
			// 		})
			// 	}
			// },
			
			//注册完成
			regist (){
				if(this.sex.trim() == ''){
					Toast.fail('請選擇性別');
				}else if(this.name.trim() == ''){
					Toast.fail('請填寫您的姓名');
				}else if(this.birth.trim() == ''){
					Toast.fail('請選擇您的生日');
				}else if(this.name.indexOf(' ') != -1){
					Toast.fail('姓名不可帶有空格');
				}else{
					let data = {
						sex: this.sex,//1男  2女
						username: this.name,
						birth: new Date(this.birth).getTime()/1000,
					}
					// Object.assign(data,this.form);
					profile(data).then(res => {
						if(res.status == 1){
							Toast.success('信息完善成功');
							
							var user = JSON.parse(localStorage.getItem('user'));
							user.name = data.username;
							user.sex = data.sex;
							var birthDate = new Date(data.birth*1000);
							user.birth = birthDate.getFullYear() + '-' + (birthDate.getMonth()+1) + '-' + birthDate.getDate();
							localStorage.setItem('user', JSON.stringify(user));
							
							this.$router.push('/user');
						}else{
							Toast.fail(res.msg);
						}
					});
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.van-nav-bar{
		background-color: #F5F7FA;
	}
	.information{
		margin-top: 0.88rem;
		padding: 0.68rem 0.6rem;
		background-color: #F5F7FA;
		
		.title{
			font-size: 0.44rem;
		}
		.title2{
			color: #777F8F;
			margin-top: 0.2rem;
			margin-bottom: 0.4rem;
		}
		.box{
			padding: 0.78rem 0.6rem 0.97rem;
			border-radius: 0.16rem;
			border: 0.01rem solid #C6CBD6;
			
			dl{
				width: 50%;
				display: inline-block;
				
				.van-radio{
					justify-content: center;
					
					.van-icon{
						border-width: 0.04rem;
					}
				}
				.sex-name{
					margin: 0.15rem 0;
				}
			}
		}
		.btn{
			width: 3.6rem;
			height: 0.88rem;
			line-height: 0.88rem;
			margin: 0.56rem auto;
		}
	}
</style>
